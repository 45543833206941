import { AxiosResponse, CredentialTemplateTypes } from '@swiftctrl/api-client'
import { useMutation } from '@tanstack/react-query'
import { swiftClientWithoutErrorHandling } from '../../../../data/swiftClient'
import { trimValues } from '../../../../utils-hooks'
import { AddCredentialTemplateFormData } from './utils'

type Params = {
  onSuccess: (response: AxiosResponse<CredentialTemplateTypes, any>) => void
  onError: (error: Error) => void
}

export const useAddCredentialTemplate = ({ onSuccess, onError }: Params) =>
  useMutation({
    mutationFn: (values: AddCredentialTemplateFormData) => {
      const { credentialFormat, templateName, templateDescription } =
        trimValues(values)

      return swiftClientWithoutErrorHandling.credentialTemplate.addFrom(
        credentialFormat!.entity_id!,
        {
          template_name: templateName!,
          template_description: templateDescription || '',
        },
      )
    },
    onSuccess,
    onError,
  })
